import React, {useEffect, useState} from 'react';

import './PersonOfTheDay.scss'

function PersonOfTheDay({person, sectionDuration, googleAPIKey}){
	const [currentSection, setCurrentSection] = useState(0);

	const sections = ['bio', 'hometown', 'strengths'];

	useEffect(()=>{
		const timer = setInterval(()=>{
			setCurrentSection(currentSection => {
				return currentSection < sections.length - 1 ? currentSection + 1 : 0;
			});
		}, sectionDuration);
		return () => {
			clearInterval(timer);
		}
	}, [sectionDuration, sections.length])


	const getMajorPhrase = () => {
		const adjustedMajor = person.academic_discipline.majors.join(', ').replace(/Telecommunications*/, 'TCOM')
			.replace(/Visual Communications*/, 'VisCom')
			.replace('Communications', 'Comm Studies')
			.replace('Emerging Media Design and Development', 'EMDD');
		const article = ['a','e','i','o','u'].includes(adjustedMajor.toLowerCase().charAt(0)) ? 'an' : 'a';
		if( person.rank.toLowerCase() === 'staff' ){
			const phrases = [
				<>knows <span>the secrets of life</span></>,
				<>has the <span>wisdom of a thousand beings</span></>,
				<>can <span>spot any error… instantly</span></>,
				<>will <span>solve all your problems</span></>,
				<>is <span>prepared for any scenario</span></>,
				<>is <span>tired of your excuses</span></>,
				<>wishes <span>you would clock in correctly</span></>
			];
			return phrases[Math.floor(Math.random()*phrases.length)];
		} else {
			//language provided to aid hyphenation
			return <>is {article} <span lang="en">{adjustedMajor}</span> major</>
		}
	}

	const getTimeframePhrase = () => {
		const months = ['January','February','March','April','May','June','July','August','September','October','November','December'];

		const hireDate = new Date(`${person.hire_year}-${(months.indexOf(person.hire_month)+1).toString().padStart(2,'0')}-01`);

		const yearsEmployed = Math.round(10*(Date.now() - hireDate.getTime())/1000/60/60/24/365)/10;
		const isNewHire = (Date.now() - hireDate.getTime())/1000 < 60*60*24*100; //employed fewer than 100 days

		if( isNewHire ){
			return <>is <span>a brand new hire</span>!</>;
		}

		if( person.rank.toLowerCase() === 'staff' ){
			const phrases = [
				<>is <span>pretty ancient</span>…</>,
				<>knows <span>the true age of the universe</span>.</>,
				<>was digitized <span>long before you were born</span>.</>,
				<>practically <span>invented the Internet</span>.</>,
				<>can see <span>beyond the infinite void</span>.</>,
				<>cannot <span>ever be fooled</span>.</>,
				<>feels <span>the weight of all living beings</span>.</>,
				<>can <span>sense the weather before it arrives</span>.</>,
				<>once <span>spoke for all of humanity</span>.</>,
				<>was <span>born in the darkness</span>.</>,
				<>has walked <span>uphill in all 4 dimensions</span>.</>,
			];
			return phrases[Math.floor(Math.random()*phrases.length)];
		}

		if(yearsEmployed < 1){
			return <>started working here in <span className="no-break">{`${person.hire_month} ${person.hire_year}`}</span>.</>
		} else {
			return Math.random() >= .5 ? <>has worked here since <span className="no-break">{`${person.hire_month} ${person.hire_year}`}</span>.</> : <>is in <span>Year {yearsEmployed + .5}</span> at the Corps.</>;
		}
	}

	const getSongPhrase = () => {
		if( person.theme_song_artist ){
			return <><span className="song-name">“{person.theme_song_title}”</span><br/><i>by </i><span>{person.theme_song_artist}</span></>;
		} else {
			return <><span className="song-name">{person.theme_song_title}</span></>;
		}
	}

	const params = new URLSearchParams();
	params.append('key', googleAPIKey);
	params.append('zoom','4');
	params.append('maptype','satellite');
	params.append('format','jpg');
	params.append('size','425x530');
	params.append('scale','2');
	params.append('center', person.hometown);
	const mapURL = `https://maps.googleapis.com/maps/api/staticmap?${params.toString()}`;

	return (
		<div className="slide slide-person-of-the-day">
			<h1>{person.first_name} <span className="no-break" dangerouslySetInnerHTML={{__html:person.last_name}}></span></h1>
			<h2>{person.first_name}</h2>

			<div className={['content-sections','show-'+sections[currentSection]].join(' ')}>

				<div className="bio content">
					{person.pronoun_subjective && <div className="pronouns">{[person.pronoun_subjective,person.pronoun_possessive].join(' / ')	}</div>}
					<p>{getMajorPhrase()} and {getTimeframePhrase()}</p>
					{person.theme_song_title && person.theme_song_video_id &&
						<div className="theme-song">
							<p><span className="music-icon">♫</span> Currently jiving to</p>
							<p className="song">{getSongPhrase()}</p>
							<a href={'https://www.youtube.com/watch?v='+person.theme_song_video_id} target="_blank" rel="noreferrer noopener"><img className="qr" src={'https://api.qrserver.com/v1/create-qr-code/?data='+encodeURIComponent('https://www.youtube.com/watch?v='+person.theme_song_video_id)+'&size=1000x1000'} alt="" /></a>
						</div>
					}
				</div>

				{person.hometown &&
					<div className="hometown content">
						<p>is originally from <span className="no-break">{person.hometown}</span>.</p>
						<div className="map-wrapper" title={person.hometown.split(',')[0]}>
							<div className="map">
								<img src={mapURL} alt={`A map of ${person.first_name}’s town: ${person.hometown}`} />
							</div>
						</div>
					</div>
				}

				{person.top_5_clifton_strengths &&
					<div className="strengths content">
						<h3>is especially strong in</h3>
						<ol>
							{person.top_5_clifton_strengths.map(strength=><li key={strength}>{strength}</li>)}
						</ol>
					</div>
				}
				{!person.top_5_clifton_strengths &&
					<div className="strengths content">
						<h3>is strong in lots of things. We just don’t know which ones yet!</h3>
						<ol>
							<li>stay</li>
							<li>tuned</li>
							<li>or</li>
							<li>just</li>
							<li>ask…</li>
						</ol>
					</div>
				}

			</div>

			<div className="photo">
				<img src={person.photo.standard.medium} alt="" />
			</div>
		</div>
	);
}

export default PersonOfTheDay;