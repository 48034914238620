import React, {useEffect, useState} from 'react';

import './Countdown.scss'
import {
	differenceInCalendarDays,
	intervalToDuration
} from "date-fns";

function Countdown(props){
	const [timeParts, setTimeParts] = useState(['00','00','00','00']);
	const [video, setVideo] = useState('');
	const zeroPad = (num) => {
		if( !num ){
			return '00';
		}
		return String(num).padStart(2, '0')
	};

	useEffect(()=>{
		props.countdown['Video URL'] && setVideo(props.countdown['Video URL']);

		updateCountdown();

		const timer = setInterval( updateCountdown, 1000 );
		return () => {
			if( timer ){
				clearInterval(timer);
			}
		}
	},[]);

	const updateCountdown = () => {
		const today = new Date();
		const timeParts = props.countdown.Time.split(':');
		//adds the time to the date, but adds one day to account for timezone offset being back one day
		const endDateTime = new Date(props.countdown.Date.getFullYear(), props.countdown.Date.getMonth(), props.countdown.Date.getDate() + 1, timeParts[0], timeParts[1]);

		const remaining = intervalToDuration({
			start: endDateTime,
			end: today
		});

		const formattedParts = [
			differenceInCalendarDays(endDateTime, today),
			remaining.hours,
			remaining.minutes,
			remaining.seconds,
		].map(zeroPad);

		setTimeParts(formattedParts);
	}

	const hexToRgb = hex => {
		var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		return result ? {
			r: parseInt(result[1], 16),
			g: parseInt(result[2], 16),
			b: parseInt(result[3], 16)
		} : null;
	}

	let rgb = hexToRgb(props.countdown.Color);

    if (!rgb) {
        rgb = {
            r: 0,
            g: 0,
            b: 0
        };
        console.log('FIXME -- Google sheets color issue');
    }
    
	const backgroundColor = `rgba(${rgb.r},${rgb.g},${rgb.b},.2)`;
	const textColor = `rgba(${rgb.r},${rgb.g},${rgb.b},.7)`;

	return (
		<div className="slide slide-countdown">
			{video && <video muted autoPlay={true} loop={true} src={video} />}
			<div className="info" style={{background:backgroundColor, color:textColor}}>
				<h1 lang="en"><small>Days Until</small> <span lang="en">{props.countdown['Event Title']}</span></h1>
				<time>
					{timeParts.map((part, index)=><span key={index}>{part}</span>)}
				</time>
				<div className="stripes">
					<div style={{backgroundColor:props.countdown.Color}} />
					<div style={{backgroundColor:props.countdown.Color}} />
					<div style={{backgroundColor:props.countdown.Color}} />
				</div>
			</div>
			<p>{props.countdown.Message}</p>
		</div>
	);
}

export default Countdown;