import React from 'react';


import mountain1 from '../../images/softskills-mountain1.png';
import mountain2 from '../../images/softskills-mountain2.png';
import mountain3 from '../../images/softskills-mountain3.png';
import clouds from '../../images/softskills-clouds.png';

import HomeworkImage from '../../images/soft-skills-icons/Homework.svg';
import LaptopImage from '../../images/soft-skills-icons/Laptop.svg';
import WritingUtensilImage from '../../images/soft-skills-icons/WritingUtensil.svg';
import PhoneImage from '../../images/soft-skills-icons/Phone.svg';
import YourselfImage from '../../images/soft-skills-icons/Yourself.svg';

import {STAFF_IMAGES} from "../../data";
import './SoftSkills.css'

function SoftSkills(props){
	const ITEMS_IMAGES = {
		Homework:HomeworkImage,
		Laptop:LaptopImage,
		WritingUtensil:WritingUtensilImage,
		Phone:PhoneImage,
		Yourself:YourselfImage
	};

	const times = [
		'Wed @ 11am',
		'Wed @ 1pm',
		'Thur @ 9:30am',
		'Thur @ 2pm'
	];

	const staff = Object.keys(STAFF_IMAGES).filter(name => props[name]);
	const items = Object.keys(ITEMS_IMAGES).filter(name => props[name]);

	const staffImageClasses = ["staff-image-clip"];
	if( props.presenter && props.presenter.length > 2 ){
		staffImageClasses.push('morethantwo-staff');
	}

	return (
		<div className="slide slide-soft-skills">

			<div className="circle-container">
				<div className={staffImageClasses.join(' ')}>
					{staff.length === 1 && staff.map(presenter=><label key={presenter}><i>with</i><br/><b>{presenter}</b></label>)}
					{staff.length !== 0 && staff.map(presenter=><img key={presenter} className={['staff-image',presenter].join(' ')} src={STAFF_IMAGES[presenter]} alt=""/>)}
					{staff.length === 1 && <ul>
						{times.map(time => <li key={time}>{time}</li>)}
					</ul>}
				</div>

				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 697.81 759.64" id="staffPhotos">
					<g id="Layer_2" data-name="Layer 2">
						<g id="Artwork">
							<g className="OuterPath">
								<path id="curve" d="M349.61,736.61a317.36,317.36,0,0,1-216.19-85.06,323,323,0,0,1-26.36-445.5c55.48-65.28,132.91-104.91,218-111.6,8.38-.66,16.87-1,25.23-1a317.33,317.33,0,0,1,216.19,85.07A323,323,0,0,1,592.85,624c-55.48,65.28-132.91,104.92-218,111.6C366.46,736.28,358,736.61,349.61,736.61Z"/>
							</g>
							<text className="title" width="500">
								<textPath xlinkHref="#curve" startOffset="50%" textAnchor="middle" id="softSkillsTitle">{props.Title}</textPath>
							</text>
						</g>
					</g>
				</svg>
			</div>

			{items.length !== 0 &&
				<div className="whatyouneed">
					<h2>This week, don't forget:</h2>
					<ul className="items-needed">
						{items.length !== 0 && items.map(item => <li key={item} className={item}>
							<img src={ITEMS_IMAGES[item]} alt="" />
						</li>)}
					</ul>
				</div>
			}

			<img src={mountain1} alt="" className="mountain" />
			<img src={mountain2} alt="" className="mountain2" />
			<img src={mountain3} alt="" className="mountain3" />
			<img src={clouds} alt="" className="clouds" />

		</div>
	);
}

export default SoftSkills;