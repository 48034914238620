import React, {useRef} from 'react';

import './Video.css'

function Video({...props}){

    const slideRef = useRef(null);

    return(
        <div ref={slideRef} className="slide slide-video">
            <video autoPlay={true} onEnded={() => {
                slideRef.current.dispatchEvent(new Event('slide_complete', {bubbles: true, cancellable: false, composed: false}));
            }} muted={true} className="video" src={props.src}/>
        </div>
    );
}


export default Video