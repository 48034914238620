import React from 'react';

import './Image.css'

function Image({...props}){

    return(
        <div className="slide slide-image">
            <img className="image" src={props.src} alt=""/>
        </div>
    );
}


export default Image