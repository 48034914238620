import React, {useCallback, useEffect, useState} from 'react';
import Moment from 'react-moment';

import {ReactComponent as Cloudy} from '../images/weather-icons/cloudy.svg';
import {ReactComponent as Rainy} from '../images/weather-icons/rainy.svg';
import {ReactComponent as Snowy} from '../images/weather-icons/snowy.svg';
import {ReactComponent as Stormy} from '../images/weather-icons/stormy.svg';
import {ReactComponent as Sunny} from '../images/weather-icons/sunny.svg';
import {ReactComponent as Windy} from '../images/weather-icons/windy.svg';

import cloudyAnimation from '../weather-animations/cloudy.mp4';
import rainyAnimation from '../weather-animations/rainy.mp4';
import snowyAnimation from '../weather-animations/snowy.mp4';
import stormyAnimation from '../weather-animations/stormy.mp4';
import sunnyAnimation from '../weather-animations/sunny.mp4';
import windyAnimation from '../weather-animations/windy.mp4';

const weatherConditions = {
    windy : {
        name: 'windy',
        icon: <Windy className="weather-icon"/>,
        animation: windyAnimation
    },
    stormy : {
        name: 'stormy',
        icon: <Stormy className="weather-icon"/>,
        animation: stormyAnimation
    },
    rainy : {
        name: 'rainy',
        icon: <Rainy className="weather-icon"/>,
        animation: rainyAnimation
    },
    snowy : {
        name: 'snowy',
        icon: <Snowy className="weather-icon"/>,
        animation: snowyAnimation
    },
    cloudy : {
        name: 'cloudy',
        icon: <Cloudy className="weather-icon"/>,
        animation: cloudyAnimation
    },
    sunny : {
        name: 'sunny',
        icon: <Sunny className="weather-icon"/>,
        animation: sunnyAnimation
    },
}

function Weather({...props}){

    const [temperature, setTemperature] = useState(50);
    const [isFullscreen, goFullscreen] = useState(false);
    const [currentConditions, setCurrentConditions] = useState({});

    //skip the fullscreen weather on the first load
    const [hasCompletedInitialWeatherLoad, markInitialWeatherLoadComplete] = useState(true);

    const updateWeather = useCallback( () => {
        const KEY = '728ce1c78cff6620e3e4aaca0e760168';
        const WEATHER_API_URI = 'https://api.openweathermap.org/data/2.5/weather?q=Muncie,IN,us&units=imperial&APPID='+KEY;

        fetch(WEATHER_API_URI).then(function(response){
            return response.json();
        }).then(function(data){
            let windSpeed = data.wind.speed;
            let weatherType = data.weather[0].id;

            setTemperature(Math.round(data.main.temp));

            let conditionMatch = null;

            if(windSpeed >= 35){
                conditionMatch = weatherConditions.windy;
            }
            else if(weatherType >= 200 && weatherType <= 299){
                conditionMatch = weatherConditions.stormy;
            }
            else if((weatherType >= 500 && weatherType <= 599) || (weatherType >= 300 && weatherType <= 399)){
                conditionMatch = weatherConditions.rainy;
            }
            else if(weatherType >= 600 && weatherType <= 699){
                conditionMatch = weatherConditions.snowy;
            }
            else if((weatherType >= 700 && weatherType <= 799) || (weatherType >= 801 && weatherType <= 899)){
                conditionMatch = weatherConditions.cloudy;
            }
            else if(weatherType === 800){
                conditionMatch = weatherConditions.sunny;
            }

            setCurrentConditions(conditionMatch);

            if( hasCompletedInitialWeatherLoad ){
                console.log("going fullscreen...")
                // take over the weather
                goFullscreen(true);

                // switch back to non-weather mode after a designated period of time
                setTimeout(()=>{
                    goFullscreen(false);
                }, 1000*10);
            } else {
                markInitialWeatherLoadComplete(true);
            }
        });
    }, [hasCompletedInitialWeatherLoad]);

    const scheduleNextTakeover = useCallback( () =>{
        const date = new Date();
        const nextInterval = new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), (date.getMinutes() - (date.getMinutes() % 15)) + 15, 0, 0)
        const remainingTime = nextInterval - date;
        setTimeout(updateWeather, remainingTime);
    }, [updateWeather]);

    useEffect(() => {
        updateWeather();
        scheduleNextTakeover();
        const weatherTakeoverInterval = setInterval(scheduleNextTakeover, 1000 * 60 * 15); //15 minute refresh
        return () => clearInterval(weatherTakeoverInterval);
    }, [scheduleNextTakeover, updateWeather]);

    const addDateSuperscript = dateString => {
        //moment doesn't have HTML output for content, so we have to manually add the <sup> tag
        return <>{dateString.substr(0, dateString.length-2)}<sup>{dateString.substr(dateString.length-2,2)}</sup></>
    };

    const takeoverClass = (isFullscreen) ? 'fullscreen' : '';

    if(!currentConditions){
        return null;
    }

    return (
        <div className={[props.layoutPos,'weather',takeoverClass,currentConditions.name].join(' ')}>
            <Moment className="date" interval={1000*60*15} format="MMMM Do" filter={addDateSuperscript} />
            <Moment className="time" interval={1000*60} format="h:mm A" />
            {currentConditions.icon}
            <div className="temperature">{temperature}°</div>
            <div className="overlay" />
            <video className="weather-animation" src={currentConditions.animation} muted autoPlay={isFullscreen} loop />
        </div>
    );
}

export default Weather;