import React, {useEffect, useRef, useState} from 'react';
import {arrayIsEmpty} from "../data";
import SlidelessArea from "./SlidelessArea";

function SlideShow(props){

	const [activeSlide, setActiveSlide] = useState(0);
	const [timer, setTimer] = useState(null);

	const slideshowRef = useRef(null);

	useEffect(()=>{
		if( activeSlide === null || arrayIsEmpty(props.slides) || !props.slides[activeSlide] ){
			return;
		}

		if( props.slides[activeSlide].props.duration ){
			const newTimer = setTimeout(() => {
					setActiveSlide(
						activeSlide => {
							return ( activeSlide === props.slides.length - 1 ) ? 0 : activeSlide + 1;
						})}
				, props.slides[activeSlide].props.duration);
			setTimer(newTimer);
		} else {
			slideshowRef.current.addEventListener('slide_complete', ()=>{
				nextSlide();
			}, {
				once:true
			});
		}

		return () => {
			stopTimer();
		};
	}, [activeSlide, props.slides]);

	const nextSlide = () => {
		stopTimer();
		setActiveSlide((activeSlide === props.slides.length - 1) ? 0 : activeSlide + 1);
	};

	const getLabel = () => {
		return props.slides[activeSlide].props.label ? props.slides[activeSlide].props.label : <></>;
	};

	const stopTimer = () => {
		if( !timer ){
			return;
		}
		clearTimeout(timer);
		setTimer(null);
	}

	if( !props.slides[activeSlide] ){
		return <SlidelessArea className={props.className}/>;
	}

	return (
		<div ref={slideshowRef} className={props.className} onClick={nextSlide}>
			{props.slides.length !== 0 && <label className="slide-label">{getLabel()}</label>}
			{props.slides.length !== 0 && props.slides[activeSlide]}
		</div>
	);
}

export default SlideShow;