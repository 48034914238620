import AnnieImage from './images/staff/Annie.png';
import AndrewImage from './images/staff/Andrew.png';
import BrandonImage from './images/staff/Brandon.png';
import ElleImage from './images/staff/Elle.png';
import EmmaHImage from './images/staff/EmmaH.png';
import HunterImage from './images/staff/Hunter.png';
import JoshImage from './images/staff/Josh.png';
import KristaImage from './images/staff/Krista.png';
import KallenImage from './images/staff/Kallen.png';
import MaggieImage from './images/staff/Maggie.png';
import MorganImage from './images/staff/Morgan.png';
import RaegenImage from './images/staff/Raegen.png';
import RileyImage from './images/staff/Riley.png';
import StuartImage from './images/staff/Stuart.png';

import GuestImage from './images/staff/Guest.png';

import TylerImage from './images/staff/Tyler.png';

export const STAFF_IMAGES = {
	Annie:AnnieImage,
	Brandon:BrandonImage,
	Elle:ElleImage,
	Hunter:HunterImage,
	Josh:JoshImage,
	Kallen:KallenImage,
	Krista:KristaImage,
	Maggie:MaggieImage,
	Riley:RileyImage,
	Stuart:StuartImage,
	Guest:GuestImage,
	Andrew:AndrewImage,
	Emma:EmmaHImage,
	Morgan:MorganImage,
	Raegen:RaegenImage,
	Tyler:TylerImage,
};

export const objectIsEmpty = object => Object.keys(object).length === 0;
export const arrayIsEmpty = array => array.length === 0;

export const getToday = () => {
	const today = new Date()
	today.setHours(0,0,0,0);
	return today;
};

export const startOfDay = date => {
	const startOfDay = new Date(date);
	startOfDay.setHours(startOfDay.getHours()+5);
	startOfDay.setHours(0,0,0,0);
	return startOfDay;
}

export const endOfDay = date => {
	const endOfDay = new Date(date);
	endOfDay.setHours(endOfDay.getHours()+5);
	endOfDay.setDate(endOfDay.getDate() + 1);
	return endOfDay;
}

export const randomize = array => {
	let currentIndex = array.length;
	let temporaryValue, randomIndex;

	while (0 !== currentIndex) {
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex -= 1;

		temporaryValue = array[currentIndex];
		array[currentIndex] = array[randomIndex];
		array[randomIndex] = temporaryValue;
	}
	return array;
};

export const isInTimeRange = (start, end, dateToTest = null) => {
	if( dateToTest === null ){
		dateToTest = new Date();
	}
	if( !(start instanceof Date) ){
		start = new Date(start);
	}
	if( !(end instanceof Date) ){
		end = new Date(end);
	}
	return ( dateToTest.getTime() >= start.getTime() && dateToTest.getTime() < end.getTime() );
};

export const chunk = (arr, len) => {
	const chunks = [];
	let i = 0;
	const n = arr.length;

	while (i < n) {
		chunks.push(arr.slice(i, i + len));
		i += len;
	}
	return chunks;
};
